// import axios from '../axios.js'
import axios from "../axios";

export default {
    // 获取患者列表
    getPatient(params) {
        return axios({
            url: "/Patient/find",
            method: "get",
            params,
        });
    },
    // 恢复已删除的某个患者
    recovery(data) {
        return axios({
            url: `/Patient/recovery?cid=${data.cid}`,
            method: "post",
            data,
        });
    },
    // 永久删除患者数据
    remove(data) {
        return axios({
            url: `/Patient/remove?_id=${data._id}`,
            method: "put",
            data,
        });
    },
    // 查询数据
    search(params) {
        return axios({
            url: `/Patient/find`,
            method: "get",
            params,
        });
    },
    // 查询已删除患者数据
    DelList(params) {
        return axios({
            url: `/Patient/getAllDelPatient`,
            method: "get",
            params,
        });
    },
    // 新增患者
    addPatient(data) {
        console.log(data);
        return axios({
            url: `/Patient/addPatient?CID=${data.CID}&CNAME=${data.CNAME}&Age=${data.Age}&Sex=${data.Sex}&HospitalID=${data.HospitalID}&phone=${data.phone}&History=${data.History}&address=${data.address}`,
            method: "post",
            // traditional: true,
            data,
        });
    },

    // 删除患者
    delOne(params) {
        return axios({
            url: `/Patient/delPatient`,
            method: "get",
            params,
        });
    },

    // 确定修改
    updateCal(data) {
        return axios({
            url: `/Patient/updatePatient?_id=${data.id}&CID=${data.cid}&CNAME=${data.cname}&Age=${data.age}&Sex=${data.sex}&HospitalID=${data.hospitalId}&phone=${data.phone}&History=${data.history}&address=${data.address}`,
            method: "post",
            data,
        });
    },

    // 查看报告
    lookRport(params) {
        return axios({
            url: `/ExamSolution/getlist`,
            method: "GET",
            params,
        });
    },

    // 根据身份证和id获取某一条报告
    lookRportOne(cid, id) {
        return axios({
            url: `/ExamSolution/getlist?key=${cid}&_id=${id}`,
            method: "GET",
            params: {
                cid: "",
                id: "",
            },
        });
    },

    // 删除光照报告
    delRport(params) {
        return axios({
            url: `/ExamSolution/del`,
            method: "GET",
            params,
        });
    },

    // 拉取治疗与训练报告全部
    getReoprtALl(params) {
        return axios({
            url: `/Report/find`,
            method: "GET",
            params,
        });
    },

    // 删除VR报告
    delVRone(data) {
        return axios({
            url: `/Report/del?_id=${data._id}`,
            method: "post",
            data,
        });
    },


};