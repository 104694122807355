<template>
  <div id="app">
    <keep-alive exclude="Dashboard">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  provide() {
    return {
      // reload: this.reload,
    };
  },
  data() {
    return {
      // isRouterAlive: true,
    };
  },
  methods: {
    //  //   刷新页面
    //  reload() {
    //    this.isRouterAlive = false;
    //    this.$nextTick(function () {
    //      this.isRouterAlive = true;
    //    });
    //  },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
