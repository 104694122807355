import Vue from "vue";
import Vuex from "vuex";
import patient from "./patient/patient";
import login from "./login/login";
import VRPlan from "./Plan/VRPlan";
import MEQ from "./assessment/MEQ";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    patient,
    login,
    VRPlan,
    MEQ,
  },
});
