import { Message } from "element-ui";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import ReportList from "../components/ReportList.vue";

Vue.use(VueRouter);

// 解决路由自己跳自己的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const routes = [{
        path: "/",
        // 重定向
        redirect: "/login",
    },
    // 注册账号
    // {
    //   path: `/Register`,
    //   name: "Register",
    //   component: () => import("../views/login/Register.vue"),
    // },
    // 治疗与训练报告报告页面
    {
        path: "/Treatmentreport",
        name: "Treatmentreport",
        component: () =>
            import ("../views/report/Treatmentreport.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/ReportALL'
        },
    },
    // 单独的每一条治疗与训练报告报告页面
    {
        path: "/OneTreatmentreport/:cid/:id",
        name: "OneTreatmentreport",
        component: () =>
            import ("../views/report/OneTreatmentreport.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/ReportALL'
        },
    },
    // 光照报告
    {
        path: `/OneReport/:cname/:id`,
        name: "OneReport",
        component: () =>
            import ("../views/report/OneReport.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/ReportALL'
        },
    },
    // 单独每个量表评估的报告
    {
        path: `/Correspondence_Report/:cname/:id`,
        name: "Correspondence_Report",
        component: () =>
            import ("../views/report/Correspondence_Report.vue"),
    },
    // 修改量表评估报告
    {
        path: "/ReportUpdate/:cname/:id",
        name: "ReportUpdate",
        component: () =>
            import ("../views/report/update.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/lookRport'
        },
    },
    // 修改量表光照报告
    {
        path: "/UpdateReport/:id",
        name: "UpdateReport",
        component: () =>
            import ("../views/report/UpdateReportOne.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/ReportALL'
        },
    },
    // 修改除E6量表外评估报告
    {
        path: "/ReportUpdate2/:cname/:id",
        name: "ReportUpdate2",
        component: () =>
            import ("../views/report/Update2.vue"),
        meta: {
            guidePath: true,
            jumpPath: '/system/lookRport'
        },
    },
    // 患者问卷答题登录验证
    {
        path: "/AssessmentLogin",
        name: "AssessmentLogin",
        component: () =>
            import ("../views/assessment/QuestionnaireLogin.vue"),
    },
    {
        path: "/Assessment",
        name: "Assessment",
        component: () =>
            import ("../views/assessment/Assessment.vue"),
    },
    // 睡眠评估路由
    {
        path: "/MEQ",
        name: "MEQ",
        component: () =>
            import ("../components/assessment/MEQ.vue"),
    },
    // 睡眠质量评估
    {
        path: "/PSQI",
        name: "PSQI",
        component: () =>
            import ("../components/assessment/PSQI.vue"),
    },
    // 抑郁评估路由
    {
        path: "/HAMD",
        name: "HAMD",
        component: () =>
            import ("../components/assessment/HAMD.vue"),
    },
    // 抑郁症筛查评估
    {
        path: "/PHQ",
        name: "PHQ",
        component: () =>
            import ("../components/assessment/PHQ.vue"),
    },
    // 产后抑郁评估
    {
        path: "/EPDS",
        name: "EPDS",
        component: () =>
            import ("../components/assessment/EPDS.vue"),
    },
    // 老年抑郁评估
    {
        path: "/GDS",
        name: "GDS",
        component: () =>
            import ("../components/assessment/GDS.vue"),
    },
    // 焦虑评估
    {
        path: "/BAI",
        name: "BAI",
        component: () =>
            import ("../components/assessment/BAI.vue"),
    },
    // 嗜睡量表评测
    {
        path: "/ESS",
        name: "ESS",
        component: () =>
            import ("../components/assessment/ESS.vue"),
    }, // 生会质量评测
    {
        path: "/QOL",
        name: "QOL",
        component: () =>
            import ("../components/assessment/QOL-AD.vue"),
    },
    // 脑震荡后综合征问卷
    {
        path: "/RPQ",
        name: "RPQ",
        component: () =>
            import ("../components/assessment/RPQ.vue"),
    },
    // 脑震荡后综合征问卷
    {
        path: "/CMAI",
        name: "CMAI",
        component: () =>
            import ("../components/assessment/CMAI.vue"),
    },
    // 登录
    {
        path: "/login",
        name: "Login",
        component: () =>
            import ("../views/login/Login.vue"),
    },
    // 修改密码
    {
        path: "/Update",
        name: "Update",
        component: () =>
            import ("../views/login/Update.vue"),
    },
    // 数据可视化图片
    // {
    //   // 显示患者性别数据的饼状图
    //   path: "/Echart",
    //   name: "Echart",
    //   component: () => import("../components/Echarts.vue"),
    // },
    // 系统主页
    {
        path: "/system",
        name: "System",
        component: () =>
            import ("../views/System.vue"),
        //   路由前置守卫
        beforeEnter: (to, from, next) => {
            const token = localStorage.token;
            if (token) {
                next();
            } else {
                Message.error("身份验证已过期，请重新登录后查看,已为你跳转到登录页面");
                router.replace("/login");
            }
        },
        children: [
            // 数据可视化首页
            {
                path: "Echart",
                name: "Echart",
                meta: {
                    name: "大数据分析",
                    path: "/system/Echart",
                },
                component: () =>
                    import ("../components/Echarts.vue"),
            },
            // 患者可视化数据
            {
                path: "lookEchart/:cid",
                name: "lookEchart",
                meta: {
                    name: "患者治疗分析",
                    path: "/system/lookEchart",
                },
                component: () =>
                    import ("../components/LookEchart.vue"),
            },
            // 患者列表
            {
                path: "ReportList",
                name: "ReportList",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/ReportList'
                },
                component: ReportList,
            },
            // 已删除患者列表
            {
                path: "DelList",
                name: "DelList",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/DelList'
                },
                component: () =>
                    import ("../components/DelList.vue"),
            },
            // 患者历史记录
            {
                path: "History",
                name: "History",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/History'
                },
                component: () =>
                    import ("../components/History.vue"),
            },
            // 所有报告
            {
                path: "ReportALL",
                name: "ReportALL",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/ReportALL'
                },
                component: () =>
                    import ("../components/ReportgetAll.vue"),
            },
            // 查看光照报告
            {
                path: "LookRport",
                name: "LookRport",
                meta: {
                    name: "光照报告",
                    path: "/system/LookRport",
                    keepAlive: true, // 需要被缓存
                },
                component: () =>
                    import ("../components/LookRport.vue"),
            },
            // 光照报告可视化话 跟患者报告可视化一样的，根据医生习惯来选在操作
            {
                path: "RportEchart",
                name: "RportEchart",
                component: () =>
                    import ("../components/RportEchart.vue"),
                meta: {
                    guidePath: true,
                    jumpPath: '/system/RportEchart'
                },
            },
            // 新增患者
            {
                path: "AddPatient",
                name: "AddPatient",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/AddPatient'
                },
                component: () =>
                    import ("../components/AddPatient.vue"),
            },
            // 二维码
            {
                path: "QRCode",
                name: "QRCode",
                component: () =>
                    import ("../components/QRCode.vue"),
                meta: {
                    guidePath: true,
                    jumpPath: '/system/QRCode'
                },
            },
            // 首页
            {
                path: "Home",
                name: "Home",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/Home'
                },
                component: () =>
                    import ("../components/Home.vue"),
            },
            // VR治疗方案
            {
                path: "VRPlan",
                name: "VRPlan",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/VRPlan'
                },
                component: () =>
                    import ("../components/Vrplan.vue"),
            },
            // 光照治疗方案
            {
                path: "Beam",
                name: "Beam",
                meta: {
                    guidePath: true,
                    jumpPath: '/system/Beam'
                },
                component: () =>
                    import ("../components/Beam.vue"),
            },
            // CAVE运动治疗方案
            {
                path: "CAVE",
                name: "CAVE",
                component: () =>
                    import ("../components/CAVE.vue"),
                meta: {
                    guidePath: true,
                    jumpPath: '/system/CAVE'
                },
            },
            // 答题首页
            {
                path: "AssessmentHome",
                name: "AssessmentHome",
                component: () =>
                    import ("../views/assessment/AssessmentHome.vue"),
                meta: {
                    guidePath: true,
                    jumpPath: '/system/AssessmentHome'
                },
            },
        ],
    },
];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});


export default router;